import * as React from 'react';
import { useEffect, useState } from "react";
import { RichText } from 'prismic-reactjs';

import createLink from "../components/functions/createLink.js";
import "../styles/css/focus.css"

const Focus = ({ docs, focus, lang, setFocus }) => {

  const getUrl = typeof window !== 'undefined' ? window.location.href : '';

  function cl(link) { return createLink(link, lang); }

  const [prevFocus, setPrevFocus] = useState(null);
  const [nextFocus, setNextFocus] = useState(null);

  function closeFocus() {
    document.querySelector('.focus').classList.remove('style-active');
    document.querySelector('body').classList.remove('style-noscroll');
  }

  function verifFocus() {
    docs.forEach(function (d, i) {
      if (d.node.uid === focus.node.uid) {
        if (i > 0) {
          setPrevFocus(docs[i - 1]);
        }
        if (i < docs.length) {
          setNextFocus(docs[i + 1]);
        }
      }
    });
  }

  function pFocus() {
    setFocus(prevFocus);
  }

  function nFocus() {
    setFocus(nextFocus);
  }

  function swipeBA() {
    let mousePosition;
    let offset = [0];
    const swiper = document.querySelector('.section-2 .swiper .swiper-wrapper .container-swipe');
    const img = document.querySelector('.section-2 .swiper .swiper-wrapper .container-img');
    let isDown = false;
    let breakpoint;

    if (window.matchMedia("(min-width: 1200px)").matches) {
      breakpoint = 75;
    } else if (window.matchMedia("(min-width: 700px)").matches) {
      breakpoint = 45;
    } else {
      breakpoint = 45;
    }

    swiper.addEventListener('pointerdown', press, true);
    document.addEventListener('pointerup', released, true);
    document.addEventListener('pointermove', move, true);

    function press(e) {
      isDown = true;
      offset = [
        swiper.offsetLeft - e.clientX
      ];
    }

    function move(event) {
      event.preventDefault();
      if (isDown) {
        mousePosition = { x: event.clientX };

        if (parseInt(swiper.style.left + swiper.clientWidth) > (window.innerWidth - breakpoint)) {
          swiper.style.left = window.innerWidth - breakpoint + 'px';
          img.style.width = window.innerWidth - breakpoint + 'px';
          isDown = false;
        }
        else if (parseInt(swiper.style.left) < (window.innerWidth - (window.innerWidth - breakpoint))) {
          swiper.style.left = breakpoint + 'px';
          img.style.width = breakpoint + 'px';
          isDown = false;
        }
        else {
          swiper.style.left = (mousePosition.x + offset[0]) + 'px';
          img.style.width = (mousePosition.x + offset[0]) + 'px';
        }
      }

    }
    function released() {
      isDown = false;
    }
  }

  let mount = true;
  useEffect(() => {

    if (focus !== null) {
      if (document.querySelectorAll('.section-2').length > 0) {
        swipeBA();
        function resetSwipe() {
          const swiper = document.querySelector('.section-2 .swiper .swiper-wrapper .container-swipe');
          const img = document.querySelector('.section-2 .swiper .swiper-wrapper .container-img');
          
          if (swiper.getAttribute("style")) {
            img.removeAttribute("style");
            swiper.removeAttribute("style");
          }
        }
        resetSwipe();
      }
      if (document.querySelectorAll('.section-hero .banner').length > 0) {
        verifFocus();
        document.querySelector('.focus').scrollTop = 0;
      }
    }

  }, [mount, focus]);

  if (focus !== null) {
    let doc = JSON.parse(focus.node.dataString);
    return (
      <div className="focus">

        <div className="header">
          <div className="title">
            {RichText.asText(doc.content_pathtxt3).toUpperCase()}
          </div>
          <div className="close" onClick={() => closeFocus()}>
            FERMER
          </div>
        </div>

        <section className='section-hero'>
          <div className="wrapper">
            <div className="container-path">
              <div>
                <a href="/">{RichText.asText(doc.content_pathtxt1)}</a>
              </div>
              <div className="sep">\</div>
              <div>
                <a href={cl(doc.content_pathlnk2)}>{RichText.asText(doc.content_pathtxt2)}</a>
              </div>
              <div className="sep">\</div>
              <div>
                <a href={getUrl}>{RichText.asText(doc.content_pathtxt3)}</a>
              </div>
            </div>

            <div className="container-text">
              <h1>{RichText.asText(doc.content_title)}</h1>
              <div className="text">
                <p>{RichText.asText(doc.content_text)}</p>
                <div className="container-rs">
                  <span>{RichText.asText(doc.content_share)}</span>
                  <a href={"https://www.linkedin.com/cws/share?url=" + getUrl + "?focus=" + focus.node.uid}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                      <use xlinkHref="/img/widget/icn-linkedin.svg#content"></use>
                    </svg>
                  </a>
                  <a href={"http://www.twitter.com/share?url=" + getUrl + "?focus=" + focus.node.uid}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                      <use xlinkHref="/img/widget/icn-twitter.svg#content"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="banner">
            {doc.content_grp2.map((item) => (
              <img src={item.img.url} alt={item.img.alt} />
            ))}
          </div>
          <div className="wrapper">
            <div className="foot">
              {doc.content_grp2.map((item) => (
                <>
                  <p>{RichText.asText(item.text)}</p>
                  <div className="container-tags">
                    <div className="tag">
                      <span>{RichText.asText(item.tag1)}</span>
                    </div>
                    <div className="tag">
                      <span>{RichText.asText(item.tag2)}</span>
                    </div>
                    <div className="tag">
                      <span>{RichText.asText(item.tag3)}</span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

        </section>
        <section className="content">
          {doc.body.map((slice, l) => {
            if (slice.slice_type === "description") {
              return (
                <section key={l} className='section-1'>
                  <div className="wrapper">
                    <div className="container">
                      <div className="container-title">
                        <h2>{RichText.asText(slice.primary.title)}</h2>
                        <h3>{RichText.asText(slice.primary.subtitle)}</h3>
                        <div className="text"><RichText render={slice.primary.text} /></div>
                      </div>
                      <div className="container-list">
                        <ul>
                          {slice.items.map((item) => (
                            <li key={item.name + '-' + item.value}>
                              <div className="name">{RichText.asText(item.name)}</div>
                              <div className="val">{RichText.asText(item.value)}</div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              )
            }
            else if (slice.slice_type === "slider") {
              return (
                <section key={l} className='section-2'>
                  <div className="swiper">
                    <div className="swiper-wrapper">
                      <div className="container-swipe">
                        <div className="container-text">
                          <p>{RichText.asText(slice.primary.swipe)}</p>
                        </div>
                      </div>
                      <div className="container-img">
                        <img src={slice.primary.img1.url} alt={slice.primary.img1.alt} />
                      </div>
                    </div>
                    <div className="container-img">
                      <img src={slice.primary.img2.url} alt={slice.primary.img2.alt} />
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="foot">
                      <p>{RichText.asText(slice.primary.text)}</p>
                    </div>
                  </div>
                </section>
              )
            }
            else if (slice.slice_type === "2_blocs") {
              return (
                <section key={l} className='section-3'>
                  <div className="wrapper">
                    <div className="container-el">
                      {slice.items.map((item, i) => (
                        <div key={i} className="el">
                          <div className="text">
                            <RichText render={item.text} />
                          </div>
                          <div className="img">
                            <img src={item.img.url} alt={item.img.alt} />
                            <p>{RichText.asText(item.desc)}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              )
            }
            else if (slice.slice_type === "banner") {
              return (
                <section key={l} className='section-4'>
                  <div className="banner">
                    <img src={slice.primary.img.url} alt={slice.primary.img.alt} />
                  </div>
                  <div className="wrapper">
                    <div className="foot">
                      <p>{RichText.asText(slice.primary.text)}</p>
                    </div>
                  </div>
                </section>
              )
            }
            else if (slice.slice_type === "listing") {
              return (
                <section key={l} className='section-5'>
                  <div className="wrapper">
                    <h2>{RichText.asText(slice.primary.title)}</h2>
                    <div className="container">
                      <div className="container-title">
                        <h3>{RichText.asText(slice.primary.subtitle)}</h3>
                        <p>{RichText.asText(slice.primary.text)}</p>
                      </div>
                      <div className="container-list">
                        <p>{RichText.asText(slice.primary.desc)}</p>
                        <ul>
                          {slice.items.map((item, i) => (
                            <li key={i}>
                              <div className="icn">
                                <img src={item.img.url} alt={item.img.alt} />
                              </div>
                              <div className="text">
                                <h4>{RichText.asText(item.title)}</h4>
                                <p>{RichText.asText(item.text)}</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              )
            }
            else if (slice.slice_type === "images") {
              return (
                <section className='section-6'>
                  <div className="wrapper">
                    <div className="container">
                      {slice.items.map((item, i) => {
                        if (i <= 3) {
                          return (
                            <img key={i} src={item.img.url} alt={item.img.alt} />
                          )
                        }
                      })}
                    </div>
                  </div>
                </section>
              )
            }
            else if (slice.slice_type === "quote") {
              return (
                <section key={l} className='section-7'>
                  <div className="wrapper">
                    <div className="container">
                      <q>{RichText.asText(slice.primary.quotes)}</q>
                      <div className="infos">{RichText.asText(slice.primary.infos)}</div>
                    </div>
                  </div>
                </section>
              )
            } else { return (<React.Fragment key={l} />) }
          })}
        </section>


        {docs.map((art, a) => {
          if (art.node.uid === focus.node.uid) {
            if (a === docs.length - 1 && JSON.parse(art.node.dataString).global_linked === true) {
              if (JSON.parse(docs[a - 1].node.dataString).global_linked === true) {
                var prevdoc = JSON.parse(docs[a - 1].node.dataString);
                return (
                  <section key={a} className='section-foot'>
                    <div className="background">
                      <div className="img"></div>
                      <div className="img">
                        <img src={prevdoc.bthumbnail_img.url} alt={prevdoc.bthumbnail_img.alt} />
                      </div>
                    </div>
                    <div className="wrapper">
                      <div className="container">
                        <div className="container-el">
                          <div className="el"></div>
                          <div className="el" onClick={() => pFocus()}>
                            <div className="text">
                              <h3>{RichText.asText(prevdoc.content_title)}</h3>
                              <p>{RichText.asText(prevdoc.bthumbnail_desc)}</p>
                              <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                                <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )
              }
            }
            if (a === 0 && JSON.parse(art.node.dataString).global_linked === true) {
              if (JSON.parse(docs[a + 1].node.dataString).global_linked === true) {
                var nextdoc = JSON.parse(docs[a + 1].node.dataString);
                return (
                  <section key={a} className='section-foot'>
                    <div className="background">
                      <div className="img">
                        <img src={nextdoc.bthumbnail_img.url} alt={nextdoc.bthumbnail_img.alt} />
                      </div>
                      <div className="img"></div>
                    </div>
                    <div className="wrapper">
                      <div className="container">
                        <div className="container-el">
                          <div className="el" onClick={() => nFocus()}>
                            <div className="text">
                              <h3>{RichText.asText(nextdoc.content_title)}</h3>
                              <p>{RichText.asText(nextdoc.bthumbnail_desc)}</p>
                              <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                                <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                              </svg>
                            </div>
                          </div>
                          <div className="el"></div>
                        </div>
                      </div>
                    </div>
                  </section>
                )
              }
            }
            if (0 < a && a < docs.length - 1 && JSON.parse(art.node.dataString).global_linked === true) {
              prevdoc = JSON.parse(docs[a - 1].node.dataString);
              nextdoc = JSON.parse(docs[a + 1].node.dataString);
              return (
                <section key={a} className='section-foot'>
                  <div className="background">
                    <div className="img">
                      <img src={nextdoc.bthumbnail_img.url} alt={prevdoc.bthumbnail_img.alt} />
                    </div>
                    <div className="img">
                      <img src={prevdoc.bthumbnail_img.url} alt={nextdoc.bthumbnail_img.alt} />
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="container">
                      <div className="container-el">
                        <div className="el" onClick={() => nFocus()}>
                          <div className="text">
                            <h3>{RichText.asText(nextdoc.content_title)}</h3>
                            <p>{RichText.asText(nextdoc.bthumbnail_desc)}</p>
                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                              <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                            </svg>
                          </div>
                        </div>
                        <div className="el" onClick={() => pFocus()}>
                          <div className="text">
                            <h3>{RichText.asText(prevdoc.content_title)}</h3>
                            <p>{RichText.asText(prevdoc.bthumbnail_desc)}</p>
                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                              <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            }

          }
        })}

      </div>
    );
  } else return (
    <div className="focus">
      <div className="header">
        <div className="title"></div>
        <div className="close"></div>
      </div>
      <section className='section-hero'>
        <div className="wrapper">
          <div className="container-path">
            <div><a href="/"></a></div>
            <div className="sep">\</div>
            <div><a></a></div>
            <div className="sep">\</div>
            <div><a></a></div>
          </div>
          <div className="container-text">
            <h1></h1>
            <div className="text">
              <p></p>
              <div className="container-rs">
                <span></span>
                <a href={"https://www.linkedin.com/cws/share?url=" + getUrl + "?focus="}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/widget/icn-linkedin.svg#content"></use>
                  </svg>
                </a>
                <a href={"http://www.twitter.com/share?url=" + getUrl + "?focus="}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/widget/icn-twitter.svg#content"></use>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          <img />
        </div>
        <div className="wrapper">
          <div className="foot">
            <p></p>
            <div className="container-tags">
              <div className="tag">
                <span></span>
              </div>
              <div className="tag">
                <span></span>
              </div>
              <div className="tag">
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Focus;