import * as React from 'react'
import { useEffect, useState, useRef } from "react";
import { RichText } from 'prismic-reactjs'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import axios from "axios";

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'
import {
  reverseArr,
  convertHMS,
  $_GET,
  getCookie,
  getCookieValue,
  setCookie,
} from '../utils/mod.js'

import createLink from "./functions/createLink.js";

import IconPlay from '../icons/IconPlay.js';
import IconLess from '../icons/IconLess.js';
import IconMore from '../icons/IconMore.js';
import IconPause from '../icons/IconPause.js';
import IconSound from '../icons/IconSound.js';

const Widget = ({
  widget,
  articles,
  cat,
  lang,
  type
}) => {
  const getUrl = typeof window !== 'undefined' ? window.location.href : '';

  let JSONData = [];
  const createUrl = (link) => createLink(link, lang);

  const player = useRef();
  const search = useRef();
  const uType = useRef(); // [ true, true ] | [0] podcast | [1] article
  const uTheme = useRef(); // 
  const usDate = useRef(); // true "recent" | false "ancien"
  const usAlpha = useRef(); // null nothing | true "A-Z" | false "Z-A"
  const arrayPodcastsArticles = useRef([]);

  const [titleA, setTitleA] = useState(null);
  const [titleP, setTitleP] = useState(null);
  const [tagA, setTagA] = useState(null);
  const [imgA, setImgA] = useState(null);
  const [linkA, setLinkA] = useState(null);
  const [authorT, setAuthorT] = useState(null);
  const [nameA, setNameA] = useState(null);
  const [artHtml, setArtHtml] = useState(true);
  const [isAOP, setIsAOP] = useState(true);
  const [audioA, setAudioA] = useState(null);
  const [widgetList, setWidgetList] = useState(null);

  const [linkAusha, setLinkAusha] = useState(null);
  const [linkGoogle, setLinkGoogle] = useState(null);
  const [linkDeezer, setLinkDeezer] = useState(null);
  const [linkSpotify, setLinkSpotify] = useState(null);

  const [uDateMin, setUDateMin] = useState(2019);

  let uDateMax = new Date().getFullYear();
  function createNewArray(arr1, arr2) {
    let dateMin = new Date();
    let newArr = [];
    arr1.data.forEach(function (a, i) {
      newArr.push([arr1.data.length - i, a.name, a.description, a, 'podcast', a.published_at]);
      if (dateMin > new Date(a.published_at)) {
        dateMin = new Date(a.published_at);
      }
    });
    arr2.forEach(function (a, i) {
      let j = JSON.parse(a.node.dataString);
      newArr.push([arr1.data.length + arr2.length - i, RichText.asText(j.content_title), RichText.asText(j.content_description), a, 'article', j.global_date]);
      if (j.global_date !== null && dateMin > new Date(j.global_date)) {
        dateMin = new Date(j.global_date);
      }
    });
    newArr.sort(function (a, b) {
      if (new Date(a[5]) < new Date(b[5])) { return -1; }
      if (new Date(a[5]) > new Date(b[5])) { return 1; }
      return 0;
    });
    newArr = reverseArr(newArr);
    setUDateMin(dateMin.getFullYear());

    return newArr;
  }

  const uDate = useRef({ min: uDateMin, max: uDateMax });

  function allUthemeAreTrueOrFalse() {
    let allTrue = true, allFalse = true;
    uTheme.current.forEach(function (u) {
      if (u[1]) allFalse = false;
      if (!u[1]) allTrue = false;
    });
    return allTrue || allFalse;
  }

  function thisTagssAreTrueInUtheme(arr) {
    let arrTheme = [];
    arr.forEach(function (a) {
      cat.forEach(function (c) {
        if (a.tag.slug === c.node.slugs[0]) {
          arrTheme.push(RichText.asText(JSON.parse(c.node.dataString).name));
        }
      })
    });
    let allTheme = false;
    arrTheme.forEach(function (a) {
      uTheme.current.forEach(function (u) {
        if (u[0] === a && u[1]) {
          allTheme = true;
        }
      });
    });
    return allTheme;
  }

  function updateStateAusha(pos, reset) {
    if (reset === undefined) reset = false;
    pos = parseInt(pos);
    let arrayNow;
    arrayPodcastsArticles.current.forEach(function (a) {
      if (a[0] === pos) arrayNow = a;
    });
    if (arrayNow[4] === "podcast") {
      setIsAOP(true);
      if (arrayNow[3].html_description !== null) {
        setArtHtml('<div class="text">' + arrayNow[3].html_description + '</div>');
      }
      setTitleA(arrayNow[3].name);
      setLinkA(arrayNow[3].site_url);

      setAuthorT(RichText.asText(widget.ausha_textw));

      if (!reset) {
        setTitleP(arrayNow[3].name);
        setAudioA(arrayNow[3].audio_url);
      }

      document.querySelector('.container-podcast .main .container-nav .content').setAttribute('data-index', arrayNow[0]);

      widget.body1.forEach(function (d, i) {
        if (i === pos - 1) {
          setImgA(d.primary.img.url);
          setNameA(RichText.asText(d.primary.name));

          setLinkAusha(arrayNow[3].site_url + "/" + arrayNow[3].slug);
          setLinkGoogle(d.primary.linkg.url);
          setLinkDeezer(d.primary.linkd.url);
          setLinkSpotify(d.primary.links.url);

          let tags = "";
          d.items.forEach(function (t, k) {
            let tag = "";
            cat.forEach(function (c, j) {
              if (c.node.slugs[0] === t.tag.slug) {
                tag = JSON.parse(c.node.dataString).name;
              }
            });

            if (tag !== "")
              if (k === d.items.length - 1) {
                tags += RichText.asText(tag).toUpperCase();
              } else tags += RichText.asText(tag).toUpperCase() + ",";
          });
          setTagA(tags);
        }
      });
    } else if (arrayNow[4] === "article") {
      let json = JSON.parse(arrayNow[3].node.dataString);
      setIsAOP(false);
      setTitleA(arrayNow[1]);
      setImgA(json.content_img.url);

      setLinkAusha(undefined);
      setLinkGoogle(widget.ausha_linkg.url);
      setLinkDeezer(widget.ausha_linkd.url);
      setLinkSpotify(widget.ausha_links.url);

      setLinkA(getUrl + "?article=" + arrayNow[0]);

      let tags = "";
      json.global_grp.forEach(function (t, i) {
        let tag = "";
        cat.forEach(function (c, j) {
          if (c.node.slugs[0] === t.tag.slug) {
            tag = JSON.parse(c.node.dataString).name;
          }
        });

        if (tag.length > 0) {
          if (i === json.global_grp.length - 1) {
            tags += RichText.asText(tag).toUpperCase();
          } else tags += RichText.asText(tag).toUpperCase() + ",";
        }
      });
      setTagA(tags);

      setAuthorT(RichText.asText(json.content_titleg));
      setNameA(RichText.asText(json.content_nameg));

      let newArtHtml = '';
      json.body.forEach(function (slice, i) {
        if (slice.slice_type === "text") {
          let ul = false;
          let ol = false;
          newArtHtml += '<div class="text">';
          slice.primary.text.forEach(function (t, j) {

            if (t.type === "heading3") {
              newArtHtml += '<h3>' + t.text + '</h3>';
              if (ul) {
                ul = false;
                newArtHtml += '</ul>';
              }
              if (ol) {
                ol = false;
                newArtHtml += '</ol>';
              }
            } else if (t.type === "heading4") {
              newArtHtml += '<h4>' + t.text + '</h4>';
              if (ul) {
                ul = false;
                newArtHtml += '</ul>';
              }
              if (ol) {
                ol = false;
                newArtHtml += '</ol>';
              }
            } else if (t.type === "paragraph") {
              if (t.spans.length > 0) {
                let text = t.text;
                if (t.spans[0].type === "strong") {
                  let start = t.spans[0].start;
                  let end = t.spans[0].end;
                  let textStart = text.substring(0, start);
                  let textMiddle = text.substring(start, end);
                  let textEnd = text.substring(end);
                  let newText = textStart + '<strong>' + textMiddle + '</strong>' + textEnd;
                  textStart = textMiddle = textEnd = "";

                  return newArtHtml += '<p>' + newText + '</p>';
                }
                if (t.spans[0].type === "hyperlink") {
                  let start = t.spans[0].start;
                  let end = t.spans[0].end;
                  let textStart = text.substring(0, start);
                  let textMiddle = text.substring(start, end);
                  let textEnd = text.substring(end);
                  let newText = textStart + '<a href="' + t.spans[0].data.url + '">' + textMiddle + '</a>' + textEnd;
                  textStart = textMiddle = textEnd = "";

                  return newArtHtml += '<p>' + newText + '</p>';
                }
              }
              else {
                newArtHtml += '<p>' + t.text + '</p>';
              }
              if (ul) {
                ul = false;
                newArtHtml += '</ul>';
              }
              if (ol) {
                ol = false;
                newArtHtml += '</ol>';
              }
            } else if (t.type === "list-item") {
              if (!ul) {
                ul = true;
                newArtHtml += '<ul>';
              }
              newArtHtml += '<li>' + t.text + '</li>';
            } else if (t.type === "o-list-item") {
              if (!ol) {
                ol = true;
                newArtHtml += '<ol>';
              }
              newArtHtml += '<li>' + t.text + '</li>';
            }
          });
          newArtHtml += '</div>';
        } else if (slice.slice_type === "list") {
          newArtHtml += '<div class="list">';
          slice.items.forEach(function (item, j) {
            newArtHtml += '<div class="el">' +
              '<div class="number">' +
              '<span>' + (j + 1) + '</span>' +
              '</div>' +
              '<p>' + RichText.asText(item.text) + '</p>' +
              '</div>';
          });
          newArtHtml += '</div>';
        } else if (slice.slice_type === "timeline") {
          newArtHtml += '<div class="timeline">';
          slice.items.forEach(function (item, j) {
            newArtHtml += '<a class="el" ' + (createUrl(item.link) === null ? '' : 'href="' + createUrl(item.link) + '"') + '>' +
              '<img src="/img/widget/icn-action/icn-play.svg">' +
              '<div class="text">' +
              '<div class="title">' + RichText.asText(item.title) + '</div>' +
              '<p>' + RichText.asText(item.text) + '</p>' +
              '</div>' +
              '</a>';
          });
          newArtHtml += '</div>';
        } else if (slice.slice_type === "video") {
          newArtHtml += '<div class="video">';
          newArtHtml += '<iframe src="' + slice.primary.video.url + '" frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe>';
          newArtHtml += '</div>';
        }
      });

      setArtHtml(newArtHtml);
    }
  }

  function resetWidget() {
    updateSortDate('recent');
    widgetSearch('');
    let pos;
    // if lecture en cours
    if (getCookieValue('player_is') === "true") {
      pos = document.querySelector('.container-podcast .main .container-nav .content').getAttribute('data-index');
    } else {
      pos = document.querySelector('.container-podcast .main .content-podcast .container-list .list .container-el .el:first-child').getAttribute('data-index');
    }
    setTimeout(() => {
      updateStateAusha(pos, true);
    }, 1000);
  }

  function updateWidgetList() {
    let dataJson = arrayPodcastsArticles.current;

    if (!usDate.current && usAlpha.current != null) {
      dataJson.sort(function (a, b) {
        return a[1] - b[1] || a[5] - b[5];
      });
    } else {
      if (!usDate.current) dataJson = reverseArr(dataJson);
      if (usAlpha.current != null) {
        dataJson.sort(function (a, b) {
          if (a[1] < b[1]) { return -1; }
          if (a[1] > b[1]) { return 1; }
          return 0;
        });
        if (!usAlpha.current) dataJson = reverseArr(dataJson);
      }
    }

    let newWidgetList = "";
    dataJson.forEach((d, i) => {
      if (search.current === "" || d[1].toLowerCase().indexOf(search.current.toLowerCase()) > -1) {
        if ((!uType.current[0] && !uType.current[1])
          || (uType.current[0] && uType.current[1])
          || (uType.current[0] && d[4] === "podcast")
          || (uType.current[1] && d[4] === "article")) {
          if (new Date(d[5]).getFullYear() >= uDate.current.min && new Date(d[5]).getFullYear() <= uDate.current.max) {
            if (allUthemeAreTrueOrFalse()
              || (d[4] === "podcast" && thisTagssAreTrueInUtheme(widget.body1[d[0] - 1].items))
              || (d[4] === "article" && thisTagssAreTrueInUtheme(JSON.parse(d[3].node.dataString).global_grp))) {
              newWidgetList +=
                '<div class="el" data-index="' + d[0] + '">' +
                '<div class="container-action">' +
                '<div class="action">';

              if (d[4] === "podcast") {
                newWidgetList +=
                  '<img src="/img/widget/icn-action/icn-play.svg" />' +
                  '<div class="infos">' +
                  convertHMS(d[3].duration) +
                  '</div>';
              } else {
                newWidgetList +=
                  '<img src="/img/widget/icn-action/icn-see.svg" />';
              }
              newWidgetList +=
                '</div>' +
                '</div>' +
                '<div class="text">' +
                '<div class="title">' + d[1] + '</div>' +
                '<p>' + (d[2] === null ? "" : d[2]) + '</p>' +
                '<div class="container-tag">';

              if (d[4] === "podcast") {
                widget.body1.forEach(function (slice, j) {
                  if (d[0] === j + 1) {
                    let tags = "";
                    slice.items.forEach(function (t, k) {
                      let tag = "";
                      cat.forEach(function (c, j) {
                        if (c.node.slugs[0] === t.tag.slug) {
                          tag = JSON.parse(c.node.dataString).name;
                        }
                      });

                      if (tag.length > 0) {
                        if (k === slice.items.length - 1) {
                          tags += RichText.asText(tag).toUpperCase();
                        } else tags += RichText.asText(tag).toUpperCase() + ",";
                      }
                    });
                    tags.split(',').forEach((t, k) => {
                      newWidgetList += '<div class="tag">' + t + '</div>';
                    })
                  }
                })
              } else {
                let json = JSON.parse(d[3].node.dataString);
                let tags = "";
                json.global_grp.forEach(function (t, i) {
                  let tag = "";
                  cat.forEach(function (c, j) {
                    if (c.node.slugs[0] === t.tag.slug) {
                      tag = JSON.parse(c.node.dataString).name;
                    }
                  });

                  if (tag.length > 0) {
                    if (i === json.global_grp.length - 1) {
                      tags += RichText.asText(tag).toUpperCase();
                    } else tags += RichText.asText(tag).toUpperCase() + ",";
                  }
                });
                tags.split(',').forEach((t) => {
                  newWidgetList += '<div class="tag">' + t + '</div>';
                })
              }

              newWidgetList +=
                '</div>' +
                '</div>' +
                '</div>';
            }
          }
        }
      }
    });

    setWidgetList(newWidgetList);

    setTimeout(function () {
      updateEventWidget();
    }, 100)
  }

  function updateEventWidget() {
    document.querySelectorAll('.container-podcast .main .content-podcast .container-list .list .container-el .el').forEach(function (el) {
      el.addEventListener('click', function () {
        if (!el.classList.contains('style-active')) {
          updateStateAusha(this.getAttribute('data-index'), true);
        } else {
          updateStateAusha(this.getAttribute('data-index'));
        }
        setTimeout(function () {
          document.querySelectorAll('.container-podcast .main .content-podcast .container-list .list .container-el .el').forEach(function (r) {
            r.classList.remove('style-active');
          })
          el.classList.toggle('style-active');
          document.querySelector('.container-podcast .main .content-podcast .container-thumb .content').scrollTop = 0;
          if (window.matchMedia("(min-width: 1200px)").matches) {
          } else {
            document.querySelector('.container-podcast').classList.remove('style-list-show')
          }
        }, 100)
      });
    });

    if (getCookieValue('player_is') === "true") {
      document.querySelectorAll('.container-podcast .main .content-podcast .container-list .list .container-el .el').forEach(function (el) {
        if (el.getAttribute('data-index') === getCookieValue('player_id')) {
          el.click();
          player.current.audio.current.currentTime = getCookieValue('player_progress');
          player.current.audio.current.play();
        }
      });
    }
  }

  function updateType(t) {
    if (t.toLowerCase() === "podcast") uType.current = [!uType.current[0], uType.current[1]];
    if (t.toLowerCase() === "article") uType.current = [uType.current[0], !uType.current[1]];
    updateWidgetList();
  }

  function updateTheme(t) {
    uTheme.current.forEach(function (u) {
      if (u[0] === t) {
        u[1] = !u[1];
      }
    });
    updateWidgetList();
  }

  function updateDate(t) {
    uDate.current = t;
    updateWidgetList();

    document.querySelector('.input-range__label--min .input-range__label-container').innerHTML = t.min;
    document.querySelector('.input-range__label--max .input-range__label-container').innerHTML = t.max;
  }

  function updateSortDate(t) {
    //usAlpha.current = null;
    if (t === "recent") usDate.current = true;
    else if (t === "ancien") usDate.current = false;
    updateWidgetList();
  }

  function updateSortAlpha(t) {
    //usDate.current = true;
    if (t === "az") usAlpha.current = true;
    else if (t === "za") usAlpha.current = false;
    updateWidgetList();
  }

  function widgetFiltre() {
    let widget = document.querySelector('.container-podcast');

    let filters_els = widget.querySelectorAll('.input-filter .dropdown .container-el .el .title');
    let filters_lis = widget.querySelectorAll('.input-filter .dropdown .container-el .el li');

    let trier_lis = widget.querySelectorAll('.input-trier li');

    let filter = widget.querySelector('.input-filter');
    let trier = widget.querySelector('.input-trier');

    filter.firstChild.addEventListener('click', () => {
      if (filter.classList.contains('style-open')) {
        filter.classList.remove('style-open');
      } else {
        filter.classList.add('style-open');
      }
    })

    trier.firstChild.addEventListener('click', () => {
      if (trier.classList.contains('style-open')) {
        trier.classList.remove('style-open');
      } else {
        trier.classList.add('style-open');
      }
    })

    filters_els.forEach(function (filter, index) {
      filter.addEventListener('click', function () {
        this.closest('.el').classList.toggle('style-open');
      })
    })

    filters_lis.forEach(function (li, index) {
      li.addEventListener('click', function () {
        this.classList.toggle('style-active');
      })
    })

    trier_lis.forEach(function (li, index) {
      li.addEventListener('click', function () {
        let thisIndex = index + 1;
        let arrayNextIndex = [];
        let nextIndex = 0;
        let nb = 1;
        document.querySelectorAll('.container-podcast .main .content-podcast .container-list .container-filtre .input-trier .container-el .el').forEach(function (el) {
          el.querySelectorAll('ul li').forEach(function (li) {
            if (li.classList.contains('style-active')) {
              arrayNextIndex.push(nb);
            }
            nb++;
          });
        });

        arrayNextIndex.forEach(function (i) {
          if (thisIndex <= 2 && i >= 3) nextIndex = i;
          else if (thisIndex >= 3 && i <= 2) nextIndex = i;
        });

        document.querySelectorAll('.container-podcast .main .content-podcast .container-list .container-filtre .input-trier .container-el .el').forEach(function (el) {
          el.querySelectorAll('ul li').forEach(function (li, index) {
            li.classList.remove('style-active');
          });
        });

        if (nextIndex === 0) {
          this.classList.add('style-active');
        } else if (thisIndex <= 2 && nextIndex >= 3) {
          this.classList.add('style-active');
          if (nextIndex === 3) document.querySelector('.input-trier .container-el .el:last-child ul li:first-child').classList.add('style-active');
          else document.querySelector('.input-trier .container-el .el:last-child ul li:last-child').classList.add('style-active');
        } else if (thisIndex >= 3 && nextIndex <= 2) {
          this.classList.add('style-active');
          if (nextIndex === 1) document.querySelector('.input-trier .container-el .el:first-child ul li:first-child').classList.add('style-active');
          else document.querySelector('.input-trier .container-el .el:first-child ul li:last-child').classList.add('style-active');
        } else if (thisIndex <= 2 && nextIndex <= 2) {
          this.classList.add('style-active');
        } else if (thisIndex >= 3 && nextIndex >= 3) {
          this.classList.add('style-active');
        }
      })
    })

    if (window.matchMedia("(max-width: 1200px)").matches) {
      let inputEl = widget.querySelectorAll('.container-input');
      inputEl[1].addEventListener('click', function () {
        if (inputEl[1].nextElementSibling.classList.contains('style-open')) {
          inputEl[1].nextElementSibling.classList.remove('style-open');
        }
      });
    }
  }

  function widgetSearch(s) {
    search.current = s;
    updateWidgetList();
  }

  function init() {
    let widgetContainer = document.querySelector('.container-podcast');
    let thumb = widgetContainer.querySelector('.container-thumb');
    let thumbAction = thumb.querySelector('.head .action');
    let list = widgetContainer.querySelector('.container-list');
    let listAction = list.querySelector('.head .action');

    let nav = widgetContainer.querySelector('.container-nav .head');
    nav.addEventListener('click', function () {

      let pos = document.querySelector('.container-podcast .main .content-podcast .container-list .list .container-el .el:first-child').getAttribute('data-index');
      if (getCookieValue('firstTime') === "true") {
        setCookie('firstTime', false);
        pos = document.querySelector('.container-podcast .main .container-nav .content').getAttribute('data-index');
        updateStateAusha(pos, true);
      } else {
        if (getCookieValue('player_is') === "true") {
          pos = document.querySelector('.container-podcast .main .container-nav .content').getAttribute('data-index');
        }
        updateStateAusha(pos, true);
      }

      widgetContainer.classList.add('style-show');
      document.querySelector('body').classList.add('style-noscroll');
    })

    let close = thumb.querySelector('.close');
    close.addEventListener('click', function () {
      resetWidget();
      widgetContainer.classList.remove('style-show');
      widgetContainer.classList.remove('style-list-show');
      document.querySelector('body').classList.remove('style-noscroll');
    })

    thumbAction.addEventListener('click', function () {
      widgetContainer.classList.add('style-list-show');
      document.querySelector('header').classList.remove('style-open');
      document.querySelector('.container-podcast .background').classList.remove('style-disable');

    })
    listAction.addEventListener('click', function () {
      widgetContainer.classList.remove('style-list-show');
    })
  }

  function playPlayer(e) {
    setCookie('player_is', true);
    setCookie('player_id', document.querySelector('.container-podcast .main .container-nav .content').getAttribute('data-index'));
  }

  function pausePlayer(e) {
    setCookie('player_is', false);
  }

  function listenPlayer(e) {
    setCookie('player_progress', player.current.audio.current.currentTime);
  }

  function openThisWidget(id) {
    document.querySelector('.container-podcast .container-nav .head').click();
    document.querySelectorAll('.container-podcast .main .content-podcast .container-list .list .container-el .el').forEach(function (el, i) {
      if (parseInt(el.getAttribute('data-index')) === id) {
        el.click();
      }
    });
  }

  let mount = true;
  useEffect(() => {

    if (!getCookie('firstTime')) {
      setCookie('firstTime', true);
    }

    let bgWidget = document.querySelector('.container-podcast .background');
    let widgetPodcast = document.querySelector('.container-podcast');

    bgWidget.addEventListener('click', () => {
      if (widgetPodcast.classList.contains('style-show')) {
        widgetPodcast.classList.remove('style-show');
        widgetPodcast.classList.remove('style-list-show');
        document.querySelector('body').classList.remove('style-noscroll');
      }
    });

    search.current = "";
    usDate.current = true;
    usAlpha.current = null;
    uType.current = [false, false];

    let arrTheme = [];
    cat.forEach(function (c) {
      arrTheme.push([RichText.asText(JSON.parse(c.node.dataString).name), false]);
    })
    uTheme.current = arrTheme;

    axios({
      method: "post",
      url: "https://backend-site.matacapital.com/ausha.php",
    }).then(r => {
      JSONData = r['data'];
      arrayPodcastsArticles.current = createNewArray(JSONData, articles);

      init();
      widgetFiltre();
      updateWidgetList();
      updateStateAusha(widget.body1.length);
      let pos = document.querySelector('.container-podcast .main .content-podcast .container-list .list .container-el .el:first-child').getAttribute('data-index');
      if (getCookieValue('player_is') === "true") {
        pos = document.querySelector('.container-podcast .main .container-nav .content').getAttribute('data-index');
      }
      updateStateAusha(pos, true);

      if ($_GET('article') !== null) {
        let id = parseInt($_GET('article'));
        setTimeout(function () {
          openThisWidget(id);
        }, 100);
      }

    }).catch(r => { });

    if (!getCookie('player_is')) {
      setCookie('player_is', false);
      setCookie('player_progress', 0);
      setCookie('player_id', widget.body1.length);
    }

    document.querySelector('.rhap_progress-section').append('min');

  }, [mount]);

  return (
    <div className="container-podcast">
      <div className="background"></div>
      <div className="main">
        <div className="content-podcast">
          <div className="container-thumb">
            <div className="head">
              <div className="action">
                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
                  <use xlinkHref="/img/common/icn-arrow-2.svg#content"></use>
                </svg>
                <div className="text">
                  <span>{RichText.asText(widget.ausha_titleo)}</span>
                </div>
              </div>
              <div className="close">
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
            <div className="content">
              <div className="title">
                {titleA}
              </div>
              <div className="container-tag">
                {tagA != null &&
                  tagA.split(',').map((t, i) => (
                    <div key={i} className="tag">{t}</div>
                  ))
                }
              </div>
              <div className="media">
                <img src={imgA} />
              </div>
              <div className="foot-1">
                <div className="container-plt">
                  <div className="title">{RichText.asText(widget.ausha_titlep)}</div>
                  <div className="container-el">
                    {linkAusha !== undefined &&
                      <a href={linkA} className='el' target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                          <use xlinkHref="/img/widget/icn-apodcast.svg#content"></use>
                        </svg>
                      </a>
                    }
                    {linkGoogle !== undefined &&
                      <a href={linkGoogle} className='el' target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                          <use xlinkHref="/img/widget/icn-gpodcast.svg#content"></use>
                        </svg>
                      </a>
                    }
                    {linkDeezer !== undefined &&
                      <a href={linkDeezer} className='el' target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                          <use xlinkHref="/img/widget/icn-deezer.svg#content"></use>
                        </svg>
                      </a>
                    }
                    {linkSpotify !== undefined &&
                      <a href={linkSpotify} className='el' target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                          <use xlinkHref="/img/widget/icn-spotify.svg#content"></use>
                        </svg>
                      </a>
                    }
                  </div>
                </div>
                <div className="container-share">
                  <div className="title">{RichText.asText(widget.ausha_texts)}</div>
                  <div className="container-el">
                    <a href={"https://www.linkedin.com/cws/share?url=" + linkA} target="_blank" rel="noreferrer" className='el'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <use xlinkHref="/img/widget/icn-linkedin.svg#content"></use>
                      </svg>
                    </a>
                    <a href={"http://www.twitter.com/share?url=" + linkA} target="_blank" rel="noreferrer" className='el'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <use xlinkHref="/img/widget/icn-twitter.svg#content"></use>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="foot-2">
                <div className="author">
                  <div className="text">{authorT}</div>
                  <div className="name">{nameA}</div>
                </div>
              </div>
              <div className="foot-4" dangerouslySetInnerHTML={{ __html: artHtml }} />
            </div>
          </div>
          <div className="container-list">
            <div className="head">
              <div className="action">
                <div className="text">
                  <span>{RichText.asText(widget.ausha_textr)}</span>
                </div>
                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
                  <use xlinkHref="/img/common/icn-arrow-2.svg#content"></use>
                </svg>
              </div>
            </div>
            <div className="container-filtre">
              <div className="container-input input-search">
                <input type="text" placeholder={RichText.asText(widget.ausha_search)} onInput={(e) => widgetSearch(e.target.value)} />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <use xlinkHref="/img/common/icn-loupe.svg#content"></use>
                </svg>
              </div>
              <div className="container-input input-filter">
                <div className="input">
                  <input type="text" readOnly placeholder={RichText.asText(widget.ausha_filtert)} />
                  <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
                    <use xlinkHref="/img/common/icn-arrow-2.svg#content"></use>
                  </svg>
                </div>
                <div className="dropdown">
                  <div className="container-el">
                    <div className="el">
                      <div className="title">
                        <span>{RichText.asText(widget.ausha_typet)}</span>
                        <div className="cross">
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                      <ul>
                        {widget.ausha_typed.map((a, i) => (
                          <li key={i} onClick={() => { updateType(a.select) }} >{RichText.asText(a.text)}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="el">
                      <div className="title">
                        <span>{RichText.asText(widget.ausha_themet)}</span>
                        <div className="cross">
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                      <ul>
                        {widget.ausha_filterd.map((a, i) => (
                          <React.Fragment key={i}>
                            {cat.map((c, j) => {
                              if (c.node.slugs[0] === a.link.slug) {
                                let text = RichText.asText(JSON.parse(c.node.dataString).name);
                                return (
                                  <li key={j} onClick={() => { updateTheme(text) }} >{text}</li>
                                )
                              } else { return (<React.Fragment key={j} />) }
                            })}
                          </React.Fragment>
                        ))}
                      </ul>
                    </div>
                    <div className="el">
                      <div className="title">
                        <span>{RichText.asText(widget.ausha_datet)}</span>
                        <div className="cross">
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                      <ul>
                        <InputRange
                          minValue={uDateMin}
                          maxValue={uDateMax}
                          value={uDate.current}
                          onChange={value => updateDate(value)} />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-input input-trier">
                <div className="input">
                  <input type="text" readOnly placeholder={RichText.asText(widget.ausha_sortt)} />
                  <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
                    <use xlinkHref="/img/common/icn-arrow-2.svg#content"></use>
                  </svg>
                </div>
                <div className="dropdown">
                  <div className="container-el">
                    <div className="el">
                      <ul>
                        {widget.ausha_sortdate.map((a, i) => (
                          <li key={i} onClick={() => { updateSortDate(a.select) }}>
                            <span>{RichText.asText(a.text)}</span>
                            <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                              <use xlinkHref="/img/common/icn-check.svg#content"></use>
                            </svg>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="el">
                      <ul>
                        {widget.ausha_sorta.map((a, i) => (
                          <li key={i} onClick={() => { updateSortAlpha(a.select) }}>
                            <span>{RichText.asText(a.text)}</span>
                            <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                              <use xlinkHref="/img/common/icn-check.svg#content"></use>
                            </svg>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="list">
              <div className="container-el" dangerouslySetInnerHTML={{ __html: widgetList }} />
            </div>
          </div>
        </div>
        <div className="container-nav">
          <div className="head">
            <div className="text">{RichText.asText(widget.ausha_titlec)}</div>
            <div className="container-arrow">
              <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
                <use xlinkHref="/img/common/icn-arrow-2.svg#content"></use>
              </svg>
            </div>
          </div>
          <div className="content" data-index={widget.body1.length}>
            <div className="container-title">
              <div className="title">
                {titleP}
              </div>
            </div>
            <AudioPlayer
              src={audioA}
              progressJumpSteps={{
                forward: 15000,
                backward: 15000
              }}
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              customIcons={{
                play: <IconPlay />,
                pause: <IconPause />,
                rewind: <IconLess />,
                forward: <IconMore />,
                volume: <IconSound />,
                volumeMute: <IconSound />,
              }}
              ref={player}
              onPlay={e => playPlayer(e)}
              onPause={e => pausePlayer(e)}
              onListen={e => listenPlayer(e)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Widget;
