import React from 'react';

const IconLess = props => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.82617 1.05762L6.57728 5.45496L11.8859 6.76265" stroke="#104228" strokeWidth="2" strokeLinejoin="bevel"/>
        <path d="M4.35 8.25662C3.49422 9.63388 3 11.2592 3 13C3 17.9706 7.02944 22 12 22C16.9706 22 21 17.9706 21 13C21 8.02944 16.9706 4 12 4C10.72 4 9.50243 4.26721 8.4 4.74888L8 5" stroke="#104228" strokeWidth="2"/>
        <path d="M7.4775 14.952H8.9655V11.76C8.88017 11.9147 8.75217 12.0507 8.5815 12.168C8.41083 12.2853 8.2135 12.3893 7.9895 12.48L7.5735 11.352C7.9255 11.256 8.22417 11.1227 8.4695 10.952C8.71483 10.776 8.9095 10.56 9.0535 10.304H10.2775V14.952H11.4935V16H7.4775V14.952ZM14.1774 16.168C13.7507 16.168 13.3614 16.0933 13.0094 15.944C12.6574 15.7893 12.372 15.576 12.1534 15.304C11.94 15.0267 11.812 14.7093 11.7694 14.352L13.0334 14.088C13.0547 14.2907 13.1134 14.4667 13.2094 14.616C13.3054 14.7653 13.4334 14.88 13.5934 14.96C13.7534 15.04 13.9347 15.08 14.1374 15.08C14.324 15.08 14.4947 15.0427 14.6494 14.968C14.804 14.888 14.924 14.7787 15.0094 14.64C15.0947 14.5013 15.1374 14.344 15.1374 14.168C15.1374 13.8747 15.036 13.6427 14.8334 13.472C14.636 13.296 14.3587 13.208 14.0014 13.208H12.3534L12.5214 10.304H15.9214V11.32H13.6174L13.5694 12.296H14.3854C14.796 12.296 15.1614 12.3787 15.4814 12.544C15.8014 12.7093 16.0494 12.9387 16.2254 13.232C16.4014 13.5253 16.4894 13.856 16.4894 14.224C16.4894 14.6027 16.3907 14.9387 16.1934 15.232C15.996 15.5253 15.7214 15.7547 15.3694 15.92C15.0174 16.0853 14.62 16.168 14.1774 16.168Z" fill="#104228"/>
    </svg>    
);

export default IconLess;