import * as React from 'react'
import { useEffect } from "react";
import { navigate } from "gatsby";
import { RichText } from 'prismic-reactjs'

import createLink from "../components/functions/createLink.js";

const Header = ({ header, lang, type }) => {
  const createUrl = (link) => createLink(link, lang);

  function linkwithhash(e, url, section) {
    e.preventDefault();
    navigate(`${createUrl(url)}#s${section}`);
    document.querySelector('header').classList.remove('style-open');
    document.querySelector('.container-podcast').classList.remove('style-show');
  }

  let mount = true;
  useEffect(() => {
    let header = document.querySelector('header');
    let burger = header.querySelector('.burger');
    let widget = document.querySelector('.container-podcast');
    let thumbAction = widget.querySelector('.container-thumb .head .action');
    let closeWidget = document.querySelector('.container-podcast .main .content-podcast .container-thumb .head .close');
    let bgWidget = document.querySelector('.container-podcast .background');

    burger.addEventListener('click', function () {
      if (header.classList.contains('style-open')) {
        header.classList.remove('style-open');
        setTimeout(() => {
          closeWidget.classList.remove('style-disable');
          bgWidget.classList.remove('style-disable');
        }, 200);
        if (window.matchMedia("(min-width: 1200px)").matches) {
          widget.classList.remove('style-show');
          widget.classList.remove('style-list-show');
        }

      } else {
        header.classList.add('style-open');
        closeWidget.classList.add('style-disable');
        bgWidget.classList.add('style-disable');
        widget.classList.remove('style-list-show');
        thumbAction.addEventListener('click', () => {
          closeWidget.classList.remove('style-disable');
        })
        if (window.matchMedia("(min-width: 1200px)").matches) {
          widget.classList.add('style-show');
        }
      }
    });

  }, [mount]);

  return (
    <header>
      <div className="head">
        <div className="wrapper">
          <a href="/" className='logo'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 59">
              <use xlinkHref="/img/common/logo.svg#content"></use>
            </svg>
          </a>
          <div className="burger">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="wrapper">
          <div className="container-link">
            <div className="container-el">
              {header.body.map((slice, i) => (
                <div className="el" key={i}>
                  <div className="title">
                    <a href={createUrl(slice.primary.link)} target={slice.primary.nt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(slice.primary.text)}</a>
                    {slice.items.length === 0 &&
                      <a href={createUrl(slice.primary.link)} target={slice.primary.nt ? "_blank" : "_self"} rel="noreferrer">
                        <svg className="link-icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                          <use xlinkHref="/img/common/icn-arrowext-1.svg#content"></use>
                        </svg>
                      </a>
                    }
                  </div>
                  {slice.items.length > 0 &&
                    <div className="container-sublink">
                      {slice.items.map((item, j) => (
                        <div className="sublink" key={j}>
                          <a href={createUrl(slice.primary.link)} onClick={e => linkwithhash(e, slice.primary.link, item.select)} >{RichText.asText(item.text)}</a>
                          <svg className='arrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                          </svg>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              ))}
            </div>
            <div className="foot">
              <p>{RichText.asText(header.foot_text)}</p>
              <div className="container-rs">
                <a href={createUrl(header.follow_lnk1)} target={header.follow_nt1 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Linkedin.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk2)} target={header.follow_nt2 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Twitter.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk4)} target={header.follow_nt4 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Youtube.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk5)} target={header.follow_nt5 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-ApplePodcasts.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk6)} target={header.follow_nt6 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Spotify.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk7)} target={header.follow_nt7 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-GooglePodcasts.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk8)} target={header.follow_nt8 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Deezer.svg#content"></use>
                  </svg>
                </a>
              </div>
              <ul>
                {header.foot_links.map((l, i) => (
                  <li key={i}>
                    <a href={createUrl(l.link)} onClick={e => linkwithhash(e, l.link, l.select)} >{RichText.asText(l.text)}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

    </header>
  )
}

export default Header;
