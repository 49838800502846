import { ContentPathLinkType } from "../../types/types";

/**
 * @param {ContentPathLinkType} link 
 * @param {string} lang 
 */
const createLink = (link, lang) => {

    let linkCreate = null;

    if (link.url) linkCreate = link.url;
    if (link.type === 'home') {
        if (link.uid) linkCreate = "/" + lang;
    }
    else {
        if (link.uid) linkCreate = "/" + lang + "/" + link.uid;
    }

    return linkCreate;
}

export default createLink;