import gsap, { Power1 } from 'gsap';

/**
 * @param {string} param 
 * @param {string} getUrl 
 * @returns {string | null}
 */
export const $_GET = (param, getUrl) => {
  var vars = {};
  const hash = typeof window !== 'undefined' ? window.location.hash : '';
  getUrl.replace(hash, '').replace(
    /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
    (m, key, value) => { // callback
      vars[key] = value !== undefined ? value : '';
    }
  );

  if (param) {
    return vars[param] ? vars[param] : null;
  }
  return vars;
}

/**
 * @param {Window} window 
 * @param {HTMLDivElement} section 
 * @param {number} windowScrollY 
 * @param {number[]} arrayWindow 
 */
export const handleSliderHorizontalScroll = (
  window,
  section,
  windowScrollY,
  arrayWindow
) => {
  /** @type {HTMLDivElement} */
  const containerEl = section.querySelector('.container-el');
  /** @type {HTMLDivElement} */
  const el = containerEl.querySelector('.el:nth-child(1)');
  /** @type {HTMLDivElement} */
  const grid = section.querySelector('.wrapper');

  const handleScroll = (marginTop = 0) => {
    const sectionFromTop = section.offsetTop + marginTop;
    const screenHeight = arrayWindow[1] / 10; // = 10vh 
    const c = windowScrollY > 0 ? windowScrollY : window.scrollY;
    const scrollValue = c - sectionFromTop + screenHeight;

    if (scrollValue > 0 && scrollValue <= (containerEl.clientWidth - grid.clientWidth)) {
      containerEl.style.transform = "translateX(-" + scrollValue + "px)";
    } else if (scrollValue < 0) {
      containerEl.style.transform = "translateX(0px)";
    } else if (scrollValue > (containerEl.clientWidth - grid.clientWidth)) {
      containerEl.style.transform = "translateX(calc(-100% + " + grid.clientWidth + 'px))';
    };
  };

  if (window.matchMedia("(min-width: 1200px)").matches) {
    if (window.matchMedia("(min-width: 1500px)").matches) {
      /* INIT HEIGHT SECTION */
      section.style.height = (containerEl.clientWidth - (grid.clientWidth - el.clientHeight) + 'px');
      window.addEventListener('scroll', () => handleScroll())
    } else {
      /* INIT HEIGHT SECTION */
      section.style.height = (containerEl.clientWidth - (grid.clientWidth - el.clientHeight) + 'px');
      window.addEventListener('scroll', () => handleScroll(100))
    }
  };
};

export const handleSliderAndMouseAnimation = () => {
  /* MOUSE */
  if (window.matchMedia("(min-width: 1200px)").matches) {
    let mousePointer = document.querySelector('.mouse-pointer');
    let mouseContainer = document.querySelector('.container-mouse');

    /**
     * @param {MouseEvent} event 
     */
    const moveCircle = (event) => {
      gsap.to(mousePointer, 0.4, {
        x: event.pageX,
        y: event.pageY,
        ease: Power1.easeOut
      });

      gsap.to(mouseContainer, 0.3, {
        x: event.pageX,
        y: event.pageY,
        ease: Power1.easeOut
      });
    }

    /**
     * @param {MouseEvent} event 
     */
    const stickCircle = (event) => {
      gsap.set(mousePointer, {
        x: event.pageX,
        y: event.pageY
      });
      gsap.set(mouseContainer, {
        x: event.pageX,
        y: event.pageY
      });
    }

    window.addEventListener('mousemove', (event) => {
      moveCircle(event);
    });
    window.addEventListener('mousewheel', (event) => {
      stickCircle(event);
    });

    const section_area = document.querySelector('.section-slider');
    const area = section_area.querySelector('.container-area');
    const [area_prev, area_active, area_next] = area.querySelectorAll('.area');
    /**
     * Removes and adds class on `mouse.` 
     * @param {HTMLDivElement} area 
     * @param {string} rmvClass1 first CSS class to remove.
     * @param {string} rmvClass2 second CSS class to remove.
     * @param {string} addClass CSS class ta add.
     */
    const handleMouseStyle = (
      area,
      rmvClass1,
      rmvClass2,
      addClass
    ) => {
      area.addEventListener('mouseenter', () => {
        mousePointer.classList.remove(`style-${rmvClass1}`, `style-${rmvClass2}`);
        mousePointer.classList.add(`style-${addClass}`);
      });
    }

    handleMouseStyle(area_prev, 'next', 'active', 'prev');
    handleMouseStyle(area_next, 'prev', 'active', 'next');
    handleMouseStyle(area_active, 'next', 'prev', 'active');

    area.addEventListener('mouseenter', () => {
      mousePointer.classList.add('style-show');
      mouseContainer.classList.add('style-show');
    });

    area.addEventListener('mouseleave', () => {
      mousePointer.classList.remove('style-show');
      mouseContainer.classList.remove('style-show');
    });
  }
  /* END MOUSE */

  /* CAROUSEL */
  const section_slider = document.querySelector('.section-slider');
  const section_sliderBtns = section_slider.querySelectorAll('.container-area .area');
  const section_sliderEl = section_slider.querySelectorAll('.container-el .el');
  let section_sliderCount = 1;

  const section_sliderTabs = section_slider.querySelectorAll('.container-tab .tab');
  const section_sliderTags = section_slider.querySelectorAll('.li-infos');
  let section_sliderNumEl = section_sliderEl.length;

  const prevAll = (elementActive) => {
    while (elementActive = elementActive.previousElementSibling) {
      elementActive.classList.add('style-prev');
    }
  };
  const nextAll = (elementActive) => {
    while (elementActive = elementActive.nextElementSibling) {
      elementActive.classList.add('style-next');
    }
  };

  /**
   * @param {HTMLDivElement} slider 
   * @param {number} count 
   * @param {string} element given class element to style.
   */
  const handleSliderStyle = (slider, count, element) => {
    slider
      .querySelector(element + ':nth-child(' + count + ')')
      .classList.add('style-active');

    const elActive = section_slider.querySelector(element + '.style-active');
    prevAll(elActive);
    nextAll(elActive);
  };

  /**
   * Removes class 'style-active', 'style-prev', 'style-next', to all children of the given element. 
   * @param {NodeListOf<HTMLDivElement>} parent 
   */
  const handleRemoveChildrenStyle = (parent) => {
    for (const children of parent) {
      children.classList.remove('style-active', 'style-prev', 'style-next');
    }
  };

  //Init style on UseEffect.
  (() => {
    handleSliderStyle(section_slider, section_sliderCount, '.el')
    handleSliderStyle(section_slider, section_sliderCount, '.tab')
    handleSliderStyle(section_slider, section_sliderCount, '.li-infos')
  })();

  /* Desktop device */
  if (window.matchMedia("(min-width: 1200px)").matches) {
    section_sliderBtns.forEach((btn, index) => {

      btn.addEventListener('click', () => {

        if (index === 0) {
          if (section_sliderCount > 1) {
            section_sliderCount--;
          } else if (section_sliderCount === 1) {
            section_sliderCount = section_sliderNumEl;
          }
        } else if (index === 2) {
          if (section_sliderCount < section_sliderNumEl) {
            section_sliderCount++;
          } else if (section_sliderCount === section_sliderNumEl) {
            section_sliderCount = 1;
          }
        };

        if (index === 0 || index === 2) {
          // EL
          handleRemoveChildrenStyle(section_sliderEl);
          handleSliderStyle(section_slider, section_sliderCount, ".el");

          // TAB
          handleRemoveChildrenStyle(section_sliderTabs);
          handleSliderStyle(section_slider, section_sliderCount, ".tab");

          // Text
          handleRemoveChildrenStyle(section_sliderTags);
          handleSliderStyle(section_slider, section_sliderCount, ".li-infos");
        }
      });
    });
  } else {
    /* Mobile or tablet device */

    // Swipe Left / Right
    let initialX = null;

    /**
     * @param {TouchEvent} e 
     */
    const startTouch = (e) => {
      initialX = e.touches[0].clientX;
    };

    /**
     * @param {TouchEvent} e 
     */
    const moveTouch = (e) => {
      if (initialX === null) {
        return;
      }

      const currentX = e.touches[0].clientX;
      const diffX = initialX - currentX;

      if (diffX < 5) {
        if (section_sliderCount > 1) {
          section_sliderCount--;
        } else if (section_sliderCount === 1) {
          section_sliderCount = section_sliderNumEl;
        }
        // swiped left
      } else if (diffX > -5) {
        if (section_sliderCount < section_sliderNumEl) {
          section_sliderCount++;
        } else if (section_sliderCount === section_sliderNumEl) {
          section_sliderCount = 1;
        }
        // swiped right
      };

      if (diffX < 5 || diffX > -5) {
        // EL
        handleRemoveChildrenStyle(section_sliderEl);
        handleSliderStyle(section_slider, section_sliderCount, ".el");

        // TAB
        handleRemoveChildrenStyle(section_sliderTabs);
        handleSliderStyle(section_slider, section_sliderCount, ".tab");

        // Text
        handleRemoveChildrenStyle(section_sliderTags);
        handleSliderStyle(section_slider, section_sliderCount, ".li-infos");
      }

      initialX = null;
      e.preventDefault();
    };

    section_sliderBtns[1].addEventListener("touchstart", startTouch, false);
    section_sliderBtns[1].addEventListener("touchmove", moveTouch, false);
    /* END SWIPE NAVIGATION */
  }

  const autoplay = () => {
    let isPaused = false;
    const timeInterval = section_slider.querySelector('.play').getAttribute('data-interval');

    window.setInterval(function () {
      if (!isPaused) {

        if (section_sliderCount < section_sliderNumEl) {
          section_sliderCount++;
        } else if (section_sliderCount === section_sliderNumEl) {
          section_sliderCount = 1;
        }

        // EL
        handleRemoveChildrenStyle(section_sliderEl);
        handleSliderStyle(section_slider, section_sliderCount, ".el");

        // TAB
        handleRemoveChildrenStyle(section_sliderTabs);
        handleSliderStyle(section_slider, section_sliderCount, ".tab");

        // Text
        handleRemoveChildrenStyle(section_sliderTags);
        handleSliderStyle(section_slider, section_sliderCount, ".li-infos");
      }
    }, +timeInterval);

    let play = section_slider.querySelector('.play');

    play.addEventListener('click', (e) => {
      play.classList.toggle('style-play');
      if (play.classList.contains('style-play')) {
        e.preventDefault();
        isPaused = false;
      } else {
        e.preventDefault();
        isPaused = true;
      }
    })

    if (window.matchMedia("(min-width: 1200px)").matches) {
      section_sliderBtns.forEach((btn, index) => {
        btn.addEventListener('click', () => {
          if (index === 0 || index === 2) {
            isPaused = true;
            play.classList.remove('style-play');
          }
        })
      })
    } else {
      // Swipe Left / Right
      let initialX = null;

      /**
       * @param {TouchEvent} e 
       */
      const startTouch = (e) => {
        initialX = e.touches[0].clientX;
      };

      /**
       * @param {TouchEvent} e 
       */
      const moveTouch = (e) => {
        if (initialX === null) {
          return;
        }
        var currentX = e.touches[0].clientX;
        var diffX = initialX - currentX;

        if (diffX > 10 || diffX < -10) {
          isPaused = true;
          play.classList.remove('style-play');
        }

        initialX = null;
        e.preventDefault();
      };

      section_sliderBtns[1].addEventListener("touchstart", startTouch, false);
      section_sliderBtns[1].addEventListener("touchmove", moveTouch, false);
    }
  };

  autoplay();
};

export const handleSliderEngagementAnimation = () => {
  /** @type {NodeListOf<HTMLAnchorElement>} */
  const tabs = document.querySelectorAll('.section-engagement .container .container-tab .tab');
  /** @type {NodeListOf<HTMLDivElement>} */
  const texts = document.querySelectorAll('.section-engagement .container .container-el .el');
  /** @type {NodeListOf<HTMLImageElement>} */
  const imgs = document.querySelectorAll('.section-engagement .container .container-img .list-img img');

  /**
   * @param {NodeListOf<HTMLElement>} parent 
   * @param {HTMLElement} element 
   */
  const handleActiveClass = (parent, element) => {
    for (const child of parent) {
      child.classList.remove('style-active');
    }
    element.classList.add('style-active');
  }

  /**
   * @param {NodeListOf<HTMLAnchorElement>} tabs 
   * @param {HTMLAnchorElement} tab 
   * @param {number} index 
   */
  const switchActiveTab = (tabs, tab, index) => {
    handleActiveClass(tabs, tab);
    handleActiveClass(imgs, imgs[index]);
    handleActiveClass(texts, texts[index]);
  };

  tabs.forEach((tab, index) => {
    tab.addEventListener('mouseenter', () => {
      switchActiveTab(tabs, tab, index);
    });
  });

  // INIT fist child of each nodelist
  tabs[0].classList.add('style-active');
  texts[0].classList.add('style-active');
  imgs[0].classList.add('style-active');
};