import * as React from 'react'
import { navigate } from "gatsby";
import { RichText } from 'prismic-reactjs'

import createLink from "../components/functions/createLink.js";

const Footer = ({ header, footer, lang }) => {

  const createUrl = (link) => createLink(link, lang);

  function linkwithhash(e, url, section) {
    e.preventDefault();
    navigate(`${createUrl(url)}#s${section}`);
    document.querySelector('header').classList.remove('style-open');
    document.querySelector('.container-podcast').classList.remove('style-show');
  }

  function openWidget() {
    document.querySelector('.container-podcast .container-nav .head').click();
    document.querySelector('.container-thumb .head .action').click();
  }

  function openPodcast() {
    let podcast = document.querySelectorAll('.container-list .container-filtre .input-filter .dropdown .container-el .el')[0].querySelectorAll('ul li')[0];
    let article = document.querySelectorAll('.container-list .container-filtre .input-filter .dropdown .container-el .el')[0].querySelectorAll('ul li')[1];
    document.querySelector('.container-podcast .container-nav .head').click();
    document.querySelector('.container-thumb .head .action').click();
    if (article.classList.contains('style-active')) {
      article.click()
      setTimeout(() => {
        let el = document.querySelectorAll('.container-list .list .container-el .el')[0];
        el.click();
      }, 120);
    }
    if (!podcast.classList.contains('style-active')) {
      podcast.click()
      setTimeout(() => {
        let el = document.querySelectorAll('.container-list .list .container-el .el')[0];
        el.click();
      }, 120);
    }
    else { }
  }
  function openArticle() {
    let podcast = document.querySelectorAll('.container-list .container-filtre .input-filter .dropdown .container-el .el')[0].querySelectorAll('ul li')[0];
    let article = document.querySelectorAll('.container-list .container-filtre .input-filter .dropdown .container-el .el')[0].querySelectorAll('ul li')[1];
    document.querySelector('.container-podcast .container-nav .head').click();
    document.querySelector('.container-thumb .head .action').click();
    if (podcast.classList.contains('style-active')) {
      podcast.click()
      setTimeout(() => {
        let el = document.querySelectorAll('.container-list .list .container-el .el')[0];
        el.click();
      }, 120);
    }
    if (!article.classList.contains('style-active')) {
      article.click();
      setTimeout(() => {
        let el = document.querySelectorAll('.container-list .list .container-el .el')[0];
        el.click();
      }, 120);
    }
    else { }
  }

  return (
    <footer>
      <div className="wrapper">
        <div className="head">
          <a href="/home" className='logo'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 59">
              <use xlinkHref="/img/common/logo.svg#content"></use>
            </svg>
          </a>
        </div>
        <div className="container">
          <div className="container-links">
            <div className="container-el">
              {footer.body.map((item, i) => {
                if (i === footer.body.length - 1) {
                  return (
                    <div key={i} className="el">
                      <a className="title" href={createUrl(item.primary.link)} target={item.primary.nt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(item.primary.text)}</a>
                      <a href={createUrl(item.primary.link)} target={item.primary.nt ? "_blank" : "_self"} rel="noreferrer">
                        <svg className="link-icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                          <use xlinkHref="/img/common/icn-arrowext-1.svg#content"></use>
                        </svg>
                      </a>
                    </div>
                  )
                } else if (i === footer.body.length - 3) {
                  return (
                    <div key={i} className="el">
                      <a onClick={() => openWidget()} onKeyDown={() => openWidget()} className='title'>{RichText.asText(item.primary.text)}</a>
                      <ul>
                        {item.items.map((elements, el) => {
                          if (el === item.items.length - 1) {
                            return (
                              <li key={el}>
                                <a onClick={() => openArticle()} onKeyDown={() => openArticle()} key={el}>{RichText.asText(elements.text)}</a>
                              </li>
                            )
                          } else {
                            return (
                              <li key={el}>
                                <a onClick={() => openPodcast()} onKeyDown={() => openPodcast()} key={el}>{RichText.asText(elements.text)}</a>
                              </li>
                            )
                          }

                        })}
                      </ul>
                    </div>
                  )

                }

                else {
                  return (
                    <div key={i} className="el">
                      <a href={createUrl(item.primary.link)} className='title'>{RichText.asText(item.primary.text)}</a>
                      <ul>
                        {item.items.map((elements, el) => (
                          <li key={el}>
                            <a href={createUrl(item.primary.link)} onClick={e => linkwithhash(e, item.primary.link, elements.select)} key={el}>{RichText.asText(elements.text)}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                }

              })}
            </div>
          </div>
          <div className="container-pl">
            <div className="pl">
              <h3>{RichText.asText(footer.info_title)}</h3>
              <RichText render={footer.info_text} />
              <a href={footer.info_realphone.url}>{RichText.asText(footer.info_phone)}</a>
              <div className="sep"></div>
              <RichText render={footer.info_text1} />
              <a href={footer.info_realphone1.url}>{RichText.asText(footer.info_phone1)}</a>
            </div>
            <div className="pl">
              <h3>{RichText.asText(footer.follow_title)}</h3>
              <div className="container-rs">
                <a href={createUrl(header.follow_lnk1)} target={header.follow_nt1 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Linkedin.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk2)} target={header.follow_nt2 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Twitter.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk4)} target={header.follow_nt4 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Youtube.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk5)} target={header.follow_nt5 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-ApplePodcasts.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk6)} target={header.follow_nt6 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Spotify.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk7)} target={header.follow_nt7 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-GooglePodcasts.svg#content"></use>
                  </svg>
                </a>
                <a href={createUrl(header.follow_lnk8)} target={header.follow_nt8 ? "_blank" : "_self"} rel="noreferrer" className='rs'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <use xlinkHref="/img/header/icn-Deezer.svg#content"></use>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <ul>
            {header.foot_links.map((l, i) => (
              <li key={i}>
                <a href={createUrl(l.link)} onClick={e => linkwithhash(e, l.link, l.select)} >{RichText.asText(l.text)}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
