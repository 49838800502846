import React from "react";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';

const Head = ({ doc, lang, banner }) => {
	let mount = true;
	useEffect(() => { }, [mount]);

	return (
		<Helmet title={RichText.asText(doc.global_title)}>
			<meta http-equiv="content-type" content="text/html; charset=utf8" />

			<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
			<meta name="description" content={RichText.asText(doc.global_description)} />

			<meta property="og:title" content={RichText.asText(doc.global_title)} />
			<meta property="og:description" content={RichText.asText(doc.global_description)} />
			<meta property="og:image" content={doc.global_image.url} />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={RichText.asText(doc.global_title)} />
			<meta name="twitter:description" content={RichText.asText(doc.global_description)} />
			<meta name="twitter:image" content={doc.global_image.url} />

			<link rel="apple-touch-icon" sizes="180x180" href="/img/favicon/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/img/favicon/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="194x194" href="/img/favicon/favicon-194x194.png" />
			<link rel="icon" type="image/png" sizes="192x192" href="/img/favicon/android-chrome-192x192.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/img/favicon/favicon-16x16.png" />
			<link rel="manifest" href="/img/favicon/site.webmanifest" />
			<link rel="mask-icon" href="/img/favicon/safari-pinned-tab.svg" color="#104228" />
			<meta name="msapplication-TileColor" content="#00aba9" />
			<meta name="msapplication-TileImage" content="/img/favicon/mstile-144x144.png" />
			<meta name="theme-color" content="#f5e6d3" />

			<link rel="preload" href="/fonts/PPAgrandir-Bold.woff2" as="font" type="font/woff2" crossorigin />
			<link rel="preload" href="/fonts/PPAgrandir-Heavy.woff2" as="font" type="font/woff2" crossorigin />
			<link rel="preload" href="/fonts/PPAgrandir-Medium.woff2" as="font" type="font/woff2" crossorigin />
			<link rel="preload" href="/fonts/PPAgrandir-Regular.woff2" as="font" type="font/woff2" crossorigin />
			<link rel="preload" href="/fonts/PPAgrandir-Thin.woff2" as="font" type="font/woff2" crossorigin />
			<link rel="preload" href="/fonts/PPAgrandir-TightLight.woff2" as="font" type="font/woff2" crossorigin />

			<script src="https://cdn.usefathom.com/script.js" data-spa="auto" data-site="WWTZGKIC" defer />
			<script type="text/javascript"></script>
		</Helmet>
	);
};

export default Head;