import { getCookieValue } from './cookieHandler.js';

/**
 * Retreives all HTMLElement needed for handle form submission.
 * @param {string} selector
 * @returns {[HTMLFormElement, HTMLDivElement | HTMLSpanElement, HTMLButtonElement]}
 */
const selectElements = (selector) => {
	/** @type {HTMLFormElement} */
	const form = document.querySelector('.section-newsletter form');
	/** @type {HTMLDivElement | HTMLSpanElement} */
	const element = form.querySelectorAll(selector);
	/** @type {HTMLButtonElement} */
	const btn = form.querySelector('.container button');

	return [form, element, btn];
};

const formHubspotTrue = () => {
	const [form, placeholder, btn] = selectElements('.placeholder');

	setTimeout(() => {
		btn.classList.add('style-load');
		setTimeout(() => {
			btn.classList.remove('style-load');
			btn.classList.add('style-check');
			form.reset();
			placeholder.forEach((el) => {
				el.classList.remove('style-fill');
			});
		}, 1500);
		setTimeout(() => {
			btn.classList.remove('style-check');
		}, 2500)
	}, 300);
};

const formHubspotFalse = () => {
	const [, input, btn] = selectElements('.input');

	setTimeout(() => {
		btn.classList.add('style-load');
		setTimeout(() => {
			btn.classList.remove('style-load');
			input.forEach((el) => {
				el.classList.add('style-error');
			})
		}, 1500);
	}, 300);
};

/**
 * @param {string} lastname 
 * @param {string} firstname 
 * @param {string} email 
 * @param {boolean} check1 
 * @param {string} check1txt 
 * @param {boolean} check2 
 * @param {string} getUrl 
 */
const formHubspot = async (
	lastname,
	firstname,
	email,
	check1,
	check1txt,
	check2,
	getUrl,
) => {
	const url = 'https://api.hsforms.com/submissions/v3/integration/submit/4309630/be72f2c8-c9c1-40cb-b641-cec7dfbadf40';
	const data = {
		"submittedAt": Date.now(),
		"fields": [
			{
				"objectTypeId": "0-1",
				"name": "source_du_lead",
				"value": "Site internet MataCapital"
			},
			{
				"objectTypeId": "0-1",
				"name": "lastname",
				"value": lastname
			},
			{
				"objectTypeId": "0-1",
				"name": "firstname",
				"value": firstname
			},
			{
				"objectTypeId": "0-1",
				"name": "email",
				"value": email
			},
			{
				"objectTypeId": "0-1",
				"name": "consent",
				"value": check1
			},
			{
				"objectTypeId": "0-1",
				"name": "pro_investor",
				"value": check1
			},
			{
				"objectTypeId": "0-1",
				"name": "diffusion_newsletters",
				"value": check2 ? 'OUI' : 'NON'
			}
		],
		"context": {
			"hutk": getCookieValue('hubspotutk'),
			"pageUri": getUrl,
			"pageName": "Home"
		}
	}

	const response = await fetch(url, {
		headers: {
			"Content-Type": "application/json"
		},
		method: "POST",
		body: JSON.stringify(data)
	});

	if (response.ok && response.status === 200) {
		formHubspotTrue(); // Returns a 200 response if the submission is successful.
	} else if (!response.ok && response.status === 400) {
		formHubspotFalse(); // Returns a 400 error the submission is rejected.
	} else if (!response.ok && response.status === 403) {
		formHubspotFalse(); // Returns a 403 error if the portal isn't allowed to post submissions.
	} else if (!response.ok && response.status === 404) {
		formHubspotFalse(); // Returns a 404 error if the formGuid isn't found
	}
};

/**
 * @param {HTMLInputElement} input 
 * @returns {boolean}
 */
export const isEmail = (input) => {
	let regex = /^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;
	return regex.test(input.value);
};

export const handleOnChange = (e) => {
//TODO implements logic to all inputs here.
}

/**
 * @param {string} key 
 */
const convertKeyToId = (key) => {
	/** @param {string} str */
	const firstLetter = (str) => (
		str.charAt(0).toLocaleUpperCase() +
		str.substring(1)
	);
	const identifier = key.split("_")[1];

	if (identifier.includes("name")) {
		const part1 = identifier.substring(0, identifier.indexOf("n"));
		const part2 = identifier.substring(identifier.indexOf("n"));

		return "n" + firstLetter(part1) + firstLetter(part2);
	} else {
		return "n" + firstLetter(identifier);
	}
};

/**
 * @param {Record<string, unknown>} doc 
 */
export const getNewsletterFields = (doc) => (
	Object.keys(doc)
		.filter(key => (
			key === "newsletter_lastname" ||
			key === "newsletter_firstname" ||
			key === "newsletter_email")
		).map((key) => ({
			content: doc[key],
			id: convertKeyToId(key)
		}))
);

/**
 * @param {import("react").FormEvent} e 
 * @param {string} getUrl 
 */
export const handleOnSubmit = (e, getUrl) => {
	let formValid = true;
	e.preventDefault();

	/** @type {NodeListOf<HTMLInputElement>} */
	const inputs = document.querySelectorAll('.section-newsletter .container-form input');

	for (const input of inputs) {
		if (input.value != null) {
			if (input.value.length === 0) {
				formValid = false;
				input.parentElement.classList.add('style-error');
			}
			else if (input.getAttribute('type') === 'email') {
				if (!isEmail(input)) {
					formValid = false;
					input.parentElement.classList.add('style-error');
				}
				else {
					input.parentElement.classList.remove('style-error');
				}
			}
			else if (input.getAttribute('id') === 'check1') {
				if (!input.checked) {
					formValid = false;
					input.parentElement.classList.add('style-error');
				}
				else {
					input.parentElement.classList.remove('style-error');
				}
			}

			else {
				input.parentElement.classList.remove('style-error');
			}
		}
	};

	if (formValid) {
		formHubspot(
			document.querySelector('#nLastName').value,
			document.querySelector('#nFirstName').value,
			document.querySelector('#nEmail').value,
			document.querySelector('#check1').checked,
			document.querySelector('#check1Txt').textContent,
			document.querySelector('#check2').checked,
			getUrl
		);
	}
};

/**
 * @param {HTMLDivElement} container 
 * @param {HTMLButtonElement} button
 */
export const handleNewsletterOnSubmit = (container, button) => {
	/** @type {NodeListOf<HTMLSpanElement>} */
	const placeholders = container.querySelectorAll('.container .container-input .input .placeholder');
	/** @type {NodeListOf<HTMLInputElement>} */
	const inputs = container.querySelectorAll('input');
	/** @type {HTMLInputElement} */
	const checkbox = container.querySelector('.container-checkbox .checkbox:nth-child(1) input');

	let fNameValid = false;
	let lNameValid = false;
	let mailValid = false;

	/**
	 * @param {HTMLInputElement} input 
	 * @param {boolean} isEmail 
	 */
	const handleInputChangeEvent = (input, isEmail) => {
		if (input.value.length === 0 || isEmail) {
			input.parentElement.classList.add('style-error');
			return false;
		} else {
			input.parentElement.classList.remove('style-error');
			return true;
		}
	};

	inputs.forEach((input, i) => {
		input.addEventListener('input', () => {
			if (input.type !== "checkbox") {
				if (input.value !== '') {
					placeholders[i].classList.add('style-fill');
				} else if (input.value === "") {
					placeholders[i].classList.remove('style-fill');
				}
			}
		});

		input.addEventListener('change', () => {
			if (input.value != null) {
				if (input.getAttribute('type') === 'email') {
					mailValid = handleInputChangeEvent(input, !isEmail(input));
				} else if (input.getAttribute('id') === 'nLastName') {
					lNameValid = handleInputChangeEvent(input);
				} else if (input.getAttribute('id') === 'nFirstName') {
					fNameValid = handleInputChangeEvent(input);
				}
			}

			(lNameValid && fNameValid && mailValid && checkbox.checked)
				? button.classList.add('style-active')
				: button.classList.remove('style-active');
		})
	})
}