export const debugBtn = (cssClass) => {
  const btns = document.querySelectorAll(cssClass + ' .container-text .container-btn .btn');

  window.addEventListener('load', () => {
    for (const btn of btns) {
      btn.classList.add('style-disable');

      setTimeout(() => {
        btn.classList.remove('style-disable');
      }, 10);
    }
  })
};