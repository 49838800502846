import {
  AcquisitionDataType,
  AcquisitionNodeType,
  DataStringToObjectType,
  VisionDataStringToObjectType,
  ContentPathLinkType,
} from '../types/types.js';

/**
 * @param {AcquisitionDataType[]} input 
 */
export const reverseArr = (input) => {
  /** @type {AcquisitionDataType[]} */
  const ret = [];

  for (var i = input.length - 1; i >= 0; i--) {
    ret.push(input[i]);
  }

  return ret;
};

/**
 * @param {AcquisitionNodeType[]} data 
 * @param {unknown} RichText 
 * @param {{ surfMax: number, dateMin: Date }} [dates] 
 * @returns {AcquisitionDataType[]}
 */
export const insertData = (
  data,
  RichText,
  dates
  ) => {
  return data
  .map((datum, i) => {
    /** @type {DataStringToObjectType} */
    const dataInfo = JSON.parse(datum.node.dataString);

    if (dates) {
      if (dataInfo.global_date !== null && dates.dateMin > new Date(dataInfo.global_date)) {
        dates.dateMin = new Date(dataInfo.global_date);
      }
      if (dataInfo.global_meter !== null && dataInfo.global_meter > dates.surfMax) {
        dates.surfMax = +dataInfo.global_meter;
      }
    }

    return [
      data.length - i,
      RichText.asText(dataInfo.content_title),
      dataInfo.global_meter,
      datum,
      'focus',
      dataInfo.global_date,
      dataInfo.global_linked
    ];
  })
};

/**
 * @param {AcquisitionNodeType[]} data 
 * @param {unknown} RichText 
 */
export const createNewHomeArray = (data, RichText) => {
  const newArr = insertData(data, RichText)
    .sort((a, b) => {
      if (new Date(a[5]) < new Date(b[5])) { return -1; }
      if (new Date(a[5]) > new Date(b[5])) { return 1; }
      return 0;
    });

  return reverseArr(newArr);
};

/**
 * @param {AcquisitionNodeType[]} data 
 * @param {unknown} RichText 
 * @param {Record<string, (value: React.SetStateAction<number>) => void>} useStates
 */
export const createNewExpertiseArray = (
  data, 
  RichText,
  useStates
) => { 
  let surfMin = 0;
  const dates = { surfMax: 0, dateMin: new Date() };
  const newArr = insertData(data, RichText, dates)
    .sort((a, b) => {
      if (new Date(a[5]) < new Date(b[5])) { return -1; }
      if (new Date(a[5]) > new Date(b[5])) { return 1; }
      return 0;
    });

  useStates.setDate(dates.dateMin.getFullYear());
  useStates.setSurfMin(surfMin);
  useStates.setSurfMax(dates.surfMax);

  return reverseArr(newArr);
};

/**
 * 
 * @param {unknown} arr1 
 * @param {AcquisitionNodeType[]} arr2 
 * @param {unknown} RichText 
 */
export const createNewVisionArray = (arr1, arr2, RichText) => {
  
  let newArr = [];
  if (arr1.message !== "Unauthenticated.") {
    newArr = arr1.data
    .map((datum, i) => [arr1.data.length - i, datum.name, datum.description, datum, 'podcast', datum.published_at]);
  }

  newArr = [...newArr, ...arr2
  .map((datum, i) => {
    /** @type {VisionDataStringToObjectType} */
    const infos = JSON.parse(datum.node.dataString);
    return [arr1.data.length + arr2.length - i, RichText.asText(infos.content_title), RichText.asText(infos.content_description), datum, 'article', infos.global_date];
  })]
  .sort((a, b) => {
    if (new Date(a[5]) < new Date(b[5])) { return -1; }
    if (new Date(a[5]) > new Date(b[5])) { return 1; }
    return 0;
  });

  newArr = reverseArr(newArr);
  return newArr;
};

/**
 * @param {string} uid
 * @param {AcquisitionNodeType[]} data
 * @param {(arg: AcquisitionNodeType) => void} setFocus
 */
export const openFocus = (
  uid,
  data,
  setFocus
) => {
  for (const datum of data) {
    if (datum.node.uid === uid) {
      setFocus(datum);
      document.querySelector('.focus').classList.add('style-active');
      document.querySelector('body').classList.add('style-noscroll');
    }
  };
}

/**
 * @param {AcquisitionNodeType[]} data
 * @param {(arg: AcquisitionNodeType) => void} setFocus
 */
export const projectsOpenFocus = (docs, setFocus) => {
  /** @type {NodeListOf<HTMLDivElement>} */
  const projects = document.querySelectorAll('.section-projects .container .container-el .el');
  
  for (const project of projects) {
    project.addEventListener('click', () => {
        const uid = project.dataset.href;
        openFocus(uid, docs, setFocus);
    })
  }
};

/**
 * @param {AcquisitionNodeType[]} data
 * @param {(arg: AcquisitionNodeType) => void} setFocus
 */
export const sliderOpenFocus = (data, setFocus) => {
  /** @type {NodeListOf<HTMLDivElement>} */
  const projects = document.querySelectorAll('.section-slider .container-slider .slider .container-el .el');
  /** @type {HTMLDivElement} */
  const btnOpen = document.querySelectorAll('.section-slider .container-slider .slider .container-area .area')[1];

  btnOpen.addEventListener('click', () => {
    for (const project of projects) {
      if (project.classList.contains('style-active')) {
        const uid = project.dataset.href;
        openFocus(uid, data, setFocus);
      }
    }
  })
};

/**
 * @param {React.MutableRefObject<number>} focusListMax 
 * @param {(...args: any[]) => void} openFocus 
 * @param {() => void} updateFocusList 
 * @param {AcquisitionNodeType[]} data
 * @param {(arg: AcquisitionNodeType) => void} setFocus
 */
export const updateEventFocus = (
  focusListMax,
  openFocus,
  updateFocusList,
  data,
  setFocus
) => {
  const elementClassList = '.expertise .section-projects .container .container-project .container-el'
  
  document.querySelectorAll(elementClassList + ' .el')
  .forEach((el) => {
    el.addEventListener('click', () => {
      openFocus(el.getAttribute('data-uid'), data, setFocus);
    });
  });

  if (document.querySelector(elementClassList + ' .container-more')) {
    document.querySelector(elementClassList + ' .container-more')
    .addEventListener('click', () => {
      focusListMax.current = focusListMax.current + 2;
      updateFocusList();
    });
  }
};

/**
 * @param {string} date 
 * @param {{ month: string, year: string }} init 
 */
export const getDate = (
  date,
  init = {
    month: "2-digit",
    year: "numeric"
  }
) => {
  return Intl.DateTimeFormat("fr-FR", init).format(new Date(date));
};

/**
 * @param {AcquisitionNodeType} regionsOrTypos 
 * @param {React.MutableRefObject<unknown>} uRegionOrUTypo 
 * @param {Record<"typo", ContentPathLinkType>[]} data 
 * @param {unknown} RichText 
 */
export const isTagsTrueIn = (
  regionsOrTypos,
  uRegionOrUTypo,
  data,
  RichText
) => {
  let allTheme = false;
  
  data
  .map(datum => {
    return regionsOrTypos.map(content => {
      if (datum.region.slug === content.node.slugs[0]) {
        return RichText.asText(JSON.parse(content.node.dataString).name);
      }
      return null;
    })
  })
  .forEach(datum => {
    uRegionOrUTypo.map(content => {
      if (content[0] === datum && content[1]) {
        allTheme = true;
      }
      return content;
    })
  });

  return allTheme;
};

/**
 * @param {React.MutableRefObject<unknown>} data 
 */
export const isAllFalse = (data) => {

  let allTrue = true, allFalse = true;
  
  for (const [, bool] of data.current) {
    bool ? allFalse = false : allTrue = true;
  }

  return allTrue || allFalse;
}

/**
 * @param {AcquisitionDataType[]} arrayNow 
 * @param {React.MutableRefObject<unknown>} usDate 
 * @param {React.MutableRefObject<unknown>} usAlpha 
 */
export const sortArray = (arrayNow, usDate, usAlpha) => {

  if (!usDate.current && usAlpha.current != null) {
    arrayNow.sort((a, b) => {
      return a[1] - b[1] || a[5] - b[5];
    });

    return arrayNow;

  } else {
    if (!usDate.current) arrayNow = reverseArr(arrayNow);
    if (usAlpha.current != null) {
      arrayNow.sort((a, b) => {
        if (a[1] < b[1]) { return -1; }
        if (a[1] > b[1]) { return 1; }
        return 0;
      });
      if (!usAlpha.current) arrayNow = reverseArr(arrayNow);

      return arrayNow;
    }
  }

  return arrayNow;
}