import * as React from 'react'
import { useEffect } from "react";

import Widget from "../components/Widget.js";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import Focus from "../components/Focus.js";

const Layout = ({
  header,
  articles,
  cat,
  footer,
  children,
  lang,
  type,
  focus,
  docs,
  setFocus
}) => {

  let mount = true;
  useEffect(() => {
    if (lang === "fr") {
      window.axeptioSettings = {
        clientId: "62a357028e13ae24df901137",
        cookiesVersion: "matacapital-fr",
      };

      (function (d, s) {
        var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
        e.async = true; e.src = "//static.axept.io/sdk.js";
        t.parentNode.insertBefore(e, t);
      })(document, "script");

    } else if (lang === "en") {
      window.axeptioSettings = {
        clientId: "62a357028e13ae24df901137",
        cookiesVersion: "matacapital-en",
      };

      (function (d, s) {
        var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
        e.async = true; e.src = "//static.axept.io/sdk.js";
        t.parentNode.insertBefore(e, t);
      })(document, "script");
    }

    void 0 === window._axcb && (window._axcb = []);
    window._axcb.push(function (axeptio) {
      axeptio.on("cookies:complete", function (choices) {

      })
    })

    document.querySelectorAll('a').forEach(function (a) {
      let href = String(a.getAttribute('href'));
      if (href.indexOf('pdf') > 1) {
        a.setAttribute('target', '_blank');
      }
    });

  }, [mount, lang]);

  return (
    <>
      <Focus
        docs={docs}
        setFocus={setFocus}
        focus={focus}
        lang={lang}
      />
      <Header
        header={header}
        lang={lang}
        type={type}
      />
      <Widget
        widget={header}
        cat={cat}
        lang={lang}
        type={type}
        articles={articles}
      />
        {children}
      <Footer
        header={header}
        footer={footer}
        lang={lang}
      />
    </>
  );
};

export default Layout;